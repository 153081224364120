<template>
  <el-menu class="sideNav" @select="handleSelect">
    <section>
      <!-- <el-tooltip effect="dark" content="Home" placement="right">
        <el-menu-item index="ownerdashboard"
          ><font-awesome-icon :icon="['fa', 'home']"></font-awesome-icon
        ></el-menu-item>
      </el-tooltip> -->
      <el-tooltip effect="dark" content="My Trials" placement="right">
        <el-menu-item index="ownertrials"
          ><font-awesome-icon :icon="['fa', 'running']"></font-awesome-icon
        ></el-menu-item>
      </el-tooltip>
      <el-tooltip effect="dark" content="My Dogs" placement="right">
        <el-menu-item index="ownerdogs"
          ><font-awesome-icon :icon="['fa', 'dog']"></font-awesome-icon
        ></el-menu-item>
      </el-tooltip>
    </section>
  </el-menu>
</template>

<script>
export default {
  methods: {
    handleSelect(key) {
      this.$router.push({ name: key });
    },
  },
};
</script>

<style scoped lang="scss">
.sideNav {
  padding-top: 20px;
}

section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.svg-inline--fa {
  padding: 0;
  margin: 0 0 0 -4px;
  font-size: 2em;
}
</style>

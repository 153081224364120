<template>
  <base-spinner v-if="isLoading"></base-spinner>
  <div class="dashboard-wrapper" v-else-if="!isLoading">
    <OwnerSidebar />
    <div class="dog-info"><DogInfo :dog="dog" /></div>
    <div class="dashboard-content">
      <DogNav />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import OwnerSidebar from "../nav/OwnerSidebar.vue";
import DogInfo from "../components/dog/DogInfo.vue";
import DogNav from "../nav/DogNav.vue";

import { getDog, getDogUpcomingTrials, getDogPastTrials } from "@/routes/dog";

export default {
  props: {
    dogId: String,
  },
  components: { OwnerSidebar, DogInfo, DogNav },
  data() {
    return {
      isLoading: true,
      token: this.$store.getters["user/getUserTokens"].idToken,
      userId: this.$store.getters["user/getUserId"],
      dog: undefined,
    };
  },
  computed: {
    checkId() {
      return this.$store.getters["user/getUserId"];
    },
    checkToken() {
      return this.$store.getters["user/getUserTokens"].idToken;
    },
  },
  watch: {
    checkId(userId) {
      this.userId = userId;
    },
    checkToken(userToken) {
      this.token = userToken;
    },
    userId(userId) {
      if (userId && this.token) {
        this.getDog();
        this.getUpcomingTrials();
        this.getPastTrials();
      }
    },
    token(token) {
      if (token && this.userId) {
        this.getDog();
        this.getUpcomingTrials();
        this.getPastTrials();
      }
    },
  },
  methods: {
    async getDog() {
      this.isLoading = true;
      if (this.token && this.userId) {
        try {
          const response = await getDog(this.token, this.userId, this.dogId);
          await this.$store.dispatch("dog/setDog", response.data);

          await this.$store.dispatch("dog/setDogActiveTitles", {
            titles: response.data.titles,
            dogId: response.data._id,
          });

          this.dog = response.data;
        } catch (error) {
          console.error(error);
          this.$message({
            showClose: true,
            message: "Error getting dog",
            type: "error",
          });
        }
        this.isLoading = false;
      } else {
        console.log("no token or userId for getDog");
      }
    },
    async getUpcomingTrials() {
      this.isLoading = true;
      if (this.token && this.userId) {
        try {
          const response = await getDogUpcomingTrials(this.token, this.dogId);
          this.$store.dispatch("dog/setDogUpcomingTrials", response.data);
        } catch (error) {
          console.error(error);
          this.$message({
            showClose: true,
            message: "Error getting upcoming trials",
            type: "error",
          });
        }
      } else {
        console.log("no token or userId for getUpcomingTrials");
      }
      this.isLoading = false;
    },
    async getPastTrials() {
      this.isLoadingPastTrials = true;
      try {
        const response = await getDogPastTrials(this.token, this.dogId);
        this.$store.dispatch("dog/setDogPastTrials", response.data);
      } catch (error) {
        console.error(error);
        this.$message({
          showClose: true,
          message: "Error getting past trials",
          type: "error",
        });
      }
      this.isLoadingPastTrials = false;
    },
  },
  async created() {
    if (this.userId) {
      await this.getDog();
    }
    if (this.userId && this.dog) {
      this.getUpcomingTrials();
      this.getPastTrials();
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
section {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.dog-info {
  width: 278px;
  background: #fff;
  box-shadow: 5px -5px 5px -5px $borderLight;
}

.dashboard-content {
  position: relative;
  width: calc(100% - 278px);
  padding: 40px;
}
</style>

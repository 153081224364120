<template>
  <div v-if="!dog"><base-spinner></base-spinner></div>
  <div v-else class="dog-info">
    <div class="dog-action-icons">
      <el-tooltip effect="dark" content="Edit Dog" placement="bottom">
        <font-awesome-icon
          @Click="handleUpdateDog"
          :icon="['fa', 'edit']"
        ></font-awesome-icon>
      </el-tooltip>
      <el-tooltip effect="dark" content="Delete Dog" placement="bottom">
        <font-awesome-icon
          @Click="handleDeleteDog"
          :icon="['far', 'trash-alt']"
        ></font-awesome-icon>
      </el-tooltip>
    </div>
    <div class="dog-avatar-medals">
      <DogAvatar :dog="dog" avatarSize="140" />
      <h1>{{ dog.formalName }}</h1>
      <DogMedalCounts :isLoading="isLoading" :dog="dog" />
    </div>
    <section class="light-text">
      <p>
        Call Name: <span>{{ dog.callName }}</span>
      </p>
      <p>
        Breed: <span>{{ dog.breed }}</span>
      </p>
      <p>
        Birthdate: <span>{{ dog.dob }}</span>
      </p>
      <p>
        Height @ Withers: <span>{{ dog.heightAtWithers }}</span>
      </p>
      <div class="jump-hieghts">
        <p>Jump Heights</p>
        <p class="venue-jump-height" v-if="dog.jumpHeight">
          Default: <span>{{ dog.jumpHeight }}</span>
        </p>
        <p
          class="venue-jump-height"
          v-for="jumpHeight in dog.venueJumpHeights"
          :key="jumpHeight.venue"
        >
          {{ jumpHeight.venue }}: <span>{{ jumpHeight.jumpHeight }}</span>
        </p>
      </div>
    </section>
    <DogTitle
      v-for="title in dogTitles"
      :key="title.itemId"
      :dog="dog"
      :title="title"
    />
  </div>
</template>

<script>
import DogAvatar from "@/ui/DogAvatar";
import DogMedalCounts from "./DogMedalCounts.vue";
import DogTitle from "./DogTitle.vue";

export default {
  props: { dog: Object },
  components: { DogAvatar, DogMedalCounts, DogTitle },
  computed: {
    isLoading() {
      return this.dog ? true : false;
    },
    dogTitles() {
      return this.$store.getters["dog/getDogActiveTitles"];
    },
  },
  methods: {
    handleUpdateDog() {
      this.$router.push({ name: "adddog", params: { addOrEdit: "edit" } });
    },
    handleDeleteDog() {
      this.$confirm(
        `Are you sure you want to delete ${this.dog.formalName}`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            await this.$store.dispatch("dog/updateDog", {
              dogId: this.dog._id,
              update: { isDeleted: true },
            });

            await this.$store.dispatch("user/removeDogFromOwner", this.dog._id);

            this.$router.push({ name: "ownerdogs" });

            this.$message({
              showClose: true,
              message: `${this.dog.formalName} deleted`,
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error DI1000 deleting dog",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Delete Cancelled",
          })
        );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 8px;
  color: $secondary;
}

.dog-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: $dogInfoSize;
  padding-top: 23px;
}

.dog-avatar-medals {
  position: relative;
  width: 100%;
  height: 250px;
  text-align: center;
  border-bottom: 1px solid $borderLight;
}

section {
  width: 100%;
  padding: 10px 20px;

  span {
    color: $secondary;
  }

  .light-text {
    color: $textSecondary;
  }
}

.dog-action-icons {
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    margin-left: 5px;
    color: $tertiary-75;
  }

  svg:hover {
    cursor: pointer;
    color: $secondary;
  }
}

.jump-hieghts {
  width: 237px;
  border-top: 1px solid $tertiary-25;
}

.venue-jump-height {
  padding-left: 15px;
}
</style>

<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    @select="handleSelect"
    class="dog-nav"
  >
    <el-menu-item index="1">Upcoming Trials</el-menu-item>
    <el-menu-item index="2">Past Trials</el-menu-item>
    <el-menu-item index="3">Registration Numbers</el-menu-item>
    <el-menu-item index="4">Handlers</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    setActiveIndex() {
      return this.$route;
    },
    checkTrialType() {
      return this.$route.params.trialType;
    },
  },
  watch: {
    setActiveIndex(router) {
      switch (router.name) {
        case "dogTrialListView":
        case "dogClassList":
          if (router.params.trialType === "upcomingTrials") {
            this.activeIndex = "1";
          }
          if (router.params.trialType === "pastTrials") {
            this.activeIndex = "2";
          }
          break;
        case "dogClassResults":
          this.activeIndex = "2";
          break;
        case "dogRegistrations":
          this.activeIndex = "3";
          break;
        case "dogHandlers":
          this.activeIndex = "4";
          break;
        default:
          this.activeIndex = "1";
      }
    },
  },
  methods: {
    handleSelect(key) {
      switch (key) {
        case "1":
          this.$router.push({
            name: "dogTrialListView",
            params: { trialType: "upcomingTrials" },
          });
          break;
        case "2":
          this.$router.push({
            name: "dogTrialListView",
            params: { trialType: "pastTrials" },
          });
          break;
        case "3":
          this.$router.push({
            name: "dogRegistrations",
          });
          break;
        case "4":
          this.$router.push({
            name: "dogHandlers",
          });
          break;
        default:
          console.log("default", key);
      }
    },
  },
  created() {
    this.handleSelect("1");
  },
};
</script>

<style lang="scss" scoped>
.dog-nav {
  margin-bottom: 10px;
  font-weight: bold;
  background: none;

  .el-menu-item:hover {
    color: $secondary;
    background: none;
  }

  .el-menu-item:focus {
    background: none;
  }

  .is-active {
    color: $secondary !important;
    border-color: $secondary;
    background: none;
  }

  .el-menu-item {
    color: $tertiary-85;
    font-size: 0.9rem;
    height: 50px;
    line-height: 50px;
  }
}
</style>

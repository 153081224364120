<template>
  <div class="dog-title">
    <span :class="{ titleEarned: dateEarned }">
      <font-awesome-icon :icon="['fa', 'trophy']"></font-awesome-icon>
    </span>
    <span>
      <p class="title">{{ title.title }}</p>
      <p class="title venue">{{ title.venue }}</p>
    </span>
    <span>
      <p class="date-earned" v-if="dateEarned">{{ dateEarned }}</p>

      <p class="date-earned" v-if="!dateEarned">{{ getTitlePoints }}</p>
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: { dog: Object, title: Object },
  data() {
    return {
      dateEarned: undefined,
      titlePoints: 0,
    };
  },
  computed: {
    formatTitleDate() {
      return this.title.dateEarned
        ? moment(this.title.dateEarned).format("MM/DD/YYYY")
        : "";
    },
    getTitlePoints() {
      const allTitles = this.$store.getters["dog/getDogActiveTitles"];

      if (allTitles) {
        const activeTitle = allTitles.find(
          (existingTitle) => existingTitle.itemId === this.title.itemId
        );

        activeTitle && this.isTitleAchieved(activeTitle);

        return activeTitle && !activeTitle.dateEarned
          ? activeTitle.titlePoints + "pts"
          : activeTitle.dateEntered;
      } else {
        return 0;
      }
    },
  },
  methods: {
    isTitleAchieved(activeTitle) {
      if (activeTitle.dateEarned) {
        this.dateEarned = activeTitle.dateEarned;
        this.titleEarned = true;
      }
    },
  },
  async created() {
    if (this.title.dateEarned) {
      this.dateEarned = this.title.dateEarned;
    } else if (!this.title.dateEarned) {
      this.dateEarned = false;
      this.titlePoints = this.title.titlePoints;
    } else {
      console.log("Title doesn't have dateEarned");
    }
  },
};
</script>

<style lang="scss" scoped>
.dog-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 20px);
  padding: 16px 10px;
  background: $primary-20;
  border-bottom: 1px solid $borderLight;
  overflow: hidden;

  svg {
    margin-right: 15px;
    height: 20px;
    width: 20px;
    color: $tertiary-50;
  }

  .titleEarned svg {
    color: $textPrimary;
  }

  .title {
    width: 135px;
    font-weight: bold;
  }

  .date-earned {
    width: 65px;
  }

  .venue {
    font-weight: normal;
    font-size: 0.9em;
  }

  p {
    margin: 0 0 3px 0;
  }
}
</style>
